<style lang="less">
.login-wrapN {
	position: absolute;
	width: 80%;
	max-width: 500px;
	min-width: 300px;
	top: 20%;
	left: 50%;
	transform: translate(-50%, 0);
	background-color: #ffffff;
	padding: 20px;
	border-radius: 6px;
	box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.04);
	border: 0;
}
.titleN {
	height: 50px;
	line-height: 50px;
	font-size: 26px;
	text-align: center;
	//color: #f3a32e;
	color: #2d8cf0;
	margin-bottom: 20px;
}
.msgf {
	font-size: 13px;
	margin: 0 0 30px 30px;
}
.pass-tips {
	span {
		color: red;
		display: inline-block;
		font-weight: bold;
	}
	ul {
		margin-left: 10px;
	}
	li {
		color: red;
	}
}
</style>
<template>
	<div>
		<div class="login-wrapN">
			<h1 class="titleN">设置新密码</h1>
			<Form ref="formItem" :model="formItem" :rules="ruleFormItem">
				<div>新密码：</div>
				<FormItem prop="password">
					<Input
						type="password"
						v-model="formItem.password"
						placeholder="请输入新密码"
					>
						<Icon type="ios-locked-outline" slot="prepend"></Icon>
					</Input>
				</FormItem>
				<div>设置新密码</div>
				<FormItem prop="finalPassword">
					<Input
						type="password"
						v-model="formItem.finalPassword"
						placeholder="请再次输入新密码"
					>
						<Icon type="ios-locked-outline" slot="prepend"></Icon>
					</Input>
				</FormItem>
				<FormItem>
					<Button type="primary" @click="submit">确认</Button>
				</FormItem>
			</Form>
			<div class="pass-tips">
				<span>密码规则</span>
				<ul>
					<li>
						同时包含大写字母、小写字母和数字，可以使用#@$!%*?&,./\()等字符
					</li>
					<li>密码长度为6-32个字符</li>
					<li>不能包含账户信息与空格</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { resetPassword } from "@/api/user/user"
import { dataToSha } from "@/utils/util"

export default {
	data() {
		return {
			formItem: {
				password: "",
				finalPassword: ""
			},
			ruleFormItem: {
				password: [
					{
						required: true,
						message: "新密码不能为空",
						trigger: "blur"
					},
					{ min: 6, message: "密码最少6个字符", trigger: "blur" },
					{ max: 32, message: "密码最多32个字符", trigger: "blur" }
				],
				finalPassword: [
					{
						required: true,
						message: "设置新密码不能为空",
						trigger: "blur"
					},
					{ min: 6, message: "密码最少6个字符", trigger: "blur" },
					{ max: 32, message: "密码最多32个字符", trigger: "blur" }
				]
			},
			value1: "",
			value2: ""
		}
	},
	methods: {
		getUrlKey(name) {
			return (
				decodeURIComponent(
					(new RegExp(`[?|&]${name}=` + "([^&;]+?)(&|#|;|$)").exec(
						window.location.href
					) || [, ""])[1].replace(/\+/g, "%20")
				) || null
			)
		},
		submit() {
			this.$refs.formItem.validate(valid => {
				if (valid) {
					const rule =
						/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$#@$!%*?&\,\.\*\(\)\/\\]{6,32}$/
					if (!rule.test(this.formItem.password)) {
						this.$Message.error("请输入符合规则的新密码")
						return
					}
					if (
						this.formItem.password === this.formItem.finalPassword &&
						this.formItem.password !== null
					) {
						this.$asyncDo(async () => {
							const res = await resetPassword({
								authKey: this.getUrlKey("auth"),
								password: dataToSha(this.formItem.password)
							})
							if (res) {
								this.$Message.success("操作成功")
								this.$router.push({
									name: "login"
								})
							}
						})
					} else {
						this.$Message.error("两次输入的密码不一致")
					}
				}
			})
		}
	}
}
</script>
